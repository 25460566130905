import { IForm } from '@/base-ui/form'

export const modalConfig: IForm = {
  title: '新建角色',
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: '角色名',
      placeholder: '请输入角色名'
    },
    {
      field: 'intro',
      type: 'select',
      label: '选择权限',
      placeholder: '请选择权限',
      options: [
        { title: '所有权限', value: '所有权限' },
        { title: '部分权限', value: '部分权限' }
      ]
    }
  ],
  colLayout: { span: 24 },
  labelWidth: '80px'
}
