
import { defineComponent } from 'vue'
import { searchFormConfig } from './config/search.config'
import { contentTableConfig } from './config/content.config'
import { modalConfig } from './config/modal.config'

import PageSearch from '@/components/page-search'
import PageContent from '@/components/page-content'
import PageModal from '@/components/page-modal'

import { usePageSearch } from '@/hooks/usePageSearch'
import { usePageModal } from '@/hooks/usePageModal'

export default defineComponent({
  name: 'role',
  components: {
    PageSearch,
    PageContent,
    PageModal
  },
  setup() {
    const newCallback = () => {
      modalConfig.title = '新建角色'
    }
    const editCallback = () => {
      modalConfig.title = '编辑角色'
    }
    const seeCallback = () => {
      modalConfig.title = '查看角色'
    }
    const [pageContentRef, handleResetClick, handleQueryClick] = usePageSearch()
    const [
      pageModalRef,
      defaultInfo,
      handleSeeData,
      handleNewData,
      handleEditData
    ] = usePageModal(newCallback, editCallback, seeCallback)
    return {
      searchFormConfig,
      contentTableConfig,
      pageContentRef,
      handleResetClick,
      handleQueryClick,
      modalConfig,
      pageModalRef,
      defaultInfo,
      handleSeeData,
      handleNewData,
      handleEditData
    }
  }
})
